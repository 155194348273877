<template>
  <div>
    <PageHeader title="Create Company" category="Company" category-logo="fa-users" />

    <div class="content content-full content-boxed">
      <div class="block block-rounded">
        <div class="block-content">
          <CompanyForm v-model="form" :error="validationErrors || {}" />
          <div class="text-right pb-4">
            <button type="submit" class="btn btn-primary" :disabled="loadingAction.create" @click="onCreateCompany">
              <span v-if="loadingAction.create"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> Create Company</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeaderDisplay';
import CompanyForm from '@/components/forms/CompanyForm';

export default {
  name: 'CompanyCreate',
  components: {
    PageHeader,
    CompanyForm
  },
  data() {
    return {
      form: {
        name: '',
        invoiceEmail: '',
        settings: {
          messageCategories: [],
          documentCategories: []
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      loadingAction: 'company/loadingAction',
      errorAction: 'company/errorAction',
      validationErrors: 'company/validationErrors'
    })
  },
  methods: {
    ...mapActions({
      createCompany: 'company/createCompany',
      listCompanys: 'company/list'
    }),
    async onCreateCompany() {
      const newCompany = await this.createCompany({ data: this.form });

      if (newCompany._id) {
        await this.listCompanys();
        this.$router.push('/companies');
        this.$toasted.success('Company created successfully!', { position: 'bottom-right', duration: 3000 });
      } else {
        this.$toasted.error('There was a problem', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
